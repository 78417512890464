import Vue from 'vue'
import Vuex from 'vuex'
import getters from "./getters";
import actions from "./action";
import mutations from "./mutations";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

let state = {
    username: '',
    isCollapse: false,
    activePath: '/review/1',// 当前激活的路由
    menuList: [],// 路由列表
    originalSubjects: [],// 初评-科目列表
    repeatedSubjects: [],// 重评-科目列表
    examInfo: {},
    examType: '',
    examUuid: '',
    examList: [],// 考试列表
}
export default new Vuex.Store({
    plugins: [createPersistedState()], //解决刷新state消失的问题
    state,
    getters,
    actions,
    mutations
});
