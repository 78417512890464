<template>
    <div style="
      min-width: 2046px;
      height: 100%;
      min-height: 1024px;
      background-color: #f2edf4;
    "
    >
        <div class="container">
            <div id="login_form">
                <div class="login_form_title">
                    清华艺术类阅卷监控系统
                </div>
            <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef">
                <el-form-item
                        prop="name"
                        class="f-f1 item-ifo"
                        style="position: relative"
                >
                    <el-input
                            class="name"
                            v-model="loginForm.name"
                            placeholder="请输入身份证号"
                            maxlength="20"
                            prefix-icon="el-icon-user-solid"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        prop="password"
                        class="f-f1 item-ifo"
                        style="position: relative; padding: 0"
                >
                    <el-input
                            class="password"
                            v-model="loginForm.password"
                            type="password"
                            placeholder="请输入密码"
                            maxlength="20"
                            id="realPwd"
                            style="width: 450px"
                            show-password
                            prefix-icon="el-icon-question"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                            type="primary"
                            class="login"
                            style="width: 450px"
                            @click="submitForm()"
                    >登录
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        </div>
    </div>
</template>


<script>
    import {login} from "../../api/login.js";
    import {mobileCheck} from "../../common/utils.js";

    export default {
        name: "Login",
        // mixins: [authMixin],
        data() {
            return {
                loginForm: {
                    name: "",
                    password: "",
                },
                // 表单验证规则对象
                loginFormRules: {
                    // 对用户名进行校验
                    name: [
                        {required: true, message: "请输入身份证号", trigger: "blur"},
                        {
                            validator: function (rule, value, callback) {
                                console.log(value);
                                console.log(/\s/g.test(value));
                                if (/\s/g.test(value) === true) {
                                    callback(new Error("身份证号不能有空格"));
                                } else {
                                    callback();
                                }
                            },
                            trigger: "blur",
                        },
                    ],
                    // 对密码进行校验
                    password: [
                        {required: true, message: "请输入密码", trigger: "blur"},
                        {min: 5, max: 15, message: "请输入正确的密码", trigger: "blur"},
                    ],
                },
            };
        },
        methods: {
            // 表单验证
            submitForm() {
                let that = this
                that.$refs.loginFormRef.validate(async (valid) => {
                    // 验证用户名和准考证号是否符合规定
                    if (!valid) return;
                    // 发送请求的操作
                    let {data} = await login(that.loginForm);
                    // console.log(data, "data");
                    // 根据返回的状态码做想关的判断
                    if (data.code != 200) return that.$message.error(data.message);
                    that.$message.success("登录成功！");
                    that.$store.commit('updateUsername', that.loginForm.name)
                    // token存储
                    window.localStorage.setItem("index-token", data.data.tokenHead + " " + data.data.token);
                    window.localStorage.setItem("teacherId", data.data.teacherId);
                    window.localStorage.setItem("roleId", data.data.roleId);

                    // 重置表单
                    that.$refs.loginFormRef.resetFields();
                    // await that.$router.push("/review/1");
                    that.$store.dispatch('GetExam').then(response => {
                        if (response.code != 200) return that.$message.error(response.message);
                        that.$store.dispatch('GenerateRoutes').then(response => {
                            if (response.code != 200) return that.$message.error(response.message);
                            that.$router.push("/review/1");
                        })
                    })
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../../assets/css/common";

    .container {
        width: 660px;
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translate(-50%, 0);
        right: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
    }

    .name ::v-deep,
    .password ::v-deep {
        &.el-input__prefix {
            left: 0;
            font-size: 20px;

            .el-input__icon::before {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &.el-input__inner {
            width: 100%;
            height: 100%;
            padding-left: 40px;
            position: absolute;
            left: 0;
            border: 0;
        }
    }

    .login_form_title{
        color: #5c307d;
        font-size: 28px;
        text-align: center;
        margin-bottom: 30px;
        font-weight: bold;
    }
</style>
