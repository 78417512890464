import axios from "axios";
import {
    SERVER_URL,
    DEVELOPMENT_SERVER_URL
} from '../config/server'

import {
    Notification,
    Message
} from "element-ui";
import router from "../router/index";

export function request(config) {
    const instance = axios.create({
        baseURL: process.env.NODE_ENV === "development" ?
            DEVELOPMENT_SERVER_URL : SERVER_URL,
        timeout: 60 * 1000,
    });

    instance.interceptors.request.use(
        (config) => {
            let token = localStorage.getItem("index-token");
            if (token) {
                config.headers.Authorization = token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (res) => {
            if (res.data.code === 400003) {
                // 未登录
                router.replace("/login");
                return res;
            }
            return res;
        },
        (error) => {
            Message.closeAll();
            // if (error.response.status === 401) {
            //   // 未登录
            //   Notification({
            //     message: "身份验证失败，请登录",
            //     duration: 1500,
            //     onClose() {
            //       console.log('error');
            //       router.push("/admin/login");
            //     },
            //   });
            //   return false;
            // }
            return Promise.reject(error);
        }
    );

    return instance(config);
}


// 合并多个请求
export function allRequest(arrRequest) {
    return axios.all(arrRequest);
}
