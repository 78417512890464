export default {
    getUsername(state) {
        return state.username;
    },
    getExamInfo(state){
        return state.examInfo;
    },
    getExamUuid(state) {
        return state.examUuid;
    },
    getExamList(state) {
        return state.examList;
    },
};
