import {request} from "./network";
import store from "../store";

//评阅概况的接口,type 初评传1,复评传2
export function readSurvey(data) {
    return request({
        method: "get",
        url: "paperInspectionData/readSurvey",
        params: {
            ...data,
            examUuid: store.getters.getExamUuid
        },
    });
}
//初评评阅明细的接口
export function originalReadDetail(data) {
    return request({
        method: "get",
        url: "paperInspectionData/originalReadDetail",
        params: {
            ...data,
            examUuid: store.getters.getExamUuid
        },
    });
}

//获取初评和复评的科目
export function getSubject(data) {
    return request({
        method: "get",
        url: "paperInspectionData/getSubject",
        params: {
            ...data,
            examUuid: store.getters.getExamUuid
        },
    });
}

// 复评评阅明细的接口
export function repeatedReadDetail(data) {
    return request({
        method: "get",
        url: "paperInspectionData/repeatedReadDetail",
        params: {
            ...data,
            examUuid: store.getters.getExamUuid
        },
    });

}
