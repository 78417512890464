import {
    request
} from "./network";

// 登录
export function login(data) {
    return request({
        method: "post",
        url: "/login/teacher",
        data,
    });
}

// 退出
export function loginOut(data) {
    return request({
        method: "post",
        url: "/login/teacher/logout",
        params: data,
    });

}

// 获取考试列表
export function getExam(data) {
    return request({
        method: "get",
        url: "/paperInspectionData/getExam",
        data,
    });

}
