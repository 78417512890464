import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/login/Index.vue'
import store from '../store'

Vue.use(VueRouter)

export const routes = [

    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登录'
        },
        hidden: true,
        component: Login
    },
    {
        path: '/',
        name: 'Index',
        hidden: true,
        component: () => import("../views/Index"),
        redirect: '/review/1', //页面默认加载的路由
    },
    // score,
]
export const basePath = [
    {
        path: '/review',
        name: 'review',
        meta: {
            title: '科目列表'
        },
        hidden: false,
        examTypeHidden: true,// true-异常组不显示
        component: () => import("../views/Index"),
        children: []
    },
    {
        path: '/repeated',
        name: 'repeated',
        meta: {
            title: '第二轮'
        },
        hidden: true,
        examTypeHidden: true,// true-异常组不显示
        component: () => import("../views/Index"),
        children: []
    },
    {
        path: '/abnormal',
        name: 'abnormal',
        meta: {
            title: '异常列表'
        },
        hidden: false,
        examTypeHidden: false,
        component: () => import("../views/Index"),
        children: [
            {
                path: 'list',
                name: 'abnormal',
                meta: {
                    title: '异常列表',
                },
                hidden: false,
                showNum: false,
                component: () => import('../views/abnormal/list'),
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push(location) {
    return originalReplace.call(this, location).catch(err => err)
}

//   运用vue-router的错误处理函数 onError 捕获错误(当捕获到了Loading chunk {n} failed的错误时，重新渲染目标页面。)
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，
    // 而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
    if (isChunkLoadFailed) {
        location.reload();
    }
})
store.dispatch('GetExam').then(response => {
    store.dispatch('GenerateRoutes').then(response => {
        return false;
    })
}).catch(error => {
    return false;
})
router.beforeEach((to, from, next) => {
    let token = window.localStorage.getItem('index-token');
    if (to.meta && to.meta.uuid) {
        window.localStorage.setItem('subjectUuid', to.meta.uuid)
    }
    if (token === null || token === "" || !token) {
        if (to.path == '/login') {
            next()
        } else {
            next('/login')

        }
    } else {
        // store.dispatch('GetExam').then(response => {
        //     next();
        // }).catch(error => {
        //     next()
        // })
        // store.dispatch('GenerateRoutes').then(response => {
        //     next();
        // }).catch(error => {
        //     next()
        // })
        next()
    }
    document.title = to.meta.title;
})

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
});
export default router
