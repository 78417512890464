import {getSubject} from "../api/reading";
import {getExam} from "../api/login"
import {basePath} from "../router";
import router from "../router";

export default {
    GetExam({commit, state}) {
        return new Promise(resolve => {
            let token = localStorage.getItem("index-token");
            if (token) {
                // 向后端请求获取考试列表
                getExam()
                    .then(res => {
                        // console.log(res)
                        if (res.data.code != 200) return resolve(res.data);
                        let examList = res.data.data;
                        let index = 0;
                        let nowIndex = examList.findIndex(item => item.examUuid == state.examUuid)
                        if (state.examUuid && nowIndex != -1) {
                            index = nowIndex;
                        }
                        commit('updateExamInfo', examList[index]);
                        commit('updateExamList', examList);
                        resolve(res.data);
                    })
                    .catch(err => {
                        resolve(err)
                    })
            }
        })
    },
    GenerateRoutes({commit}) {
        return new Promise(resolve => {
            // 向后端请求路由数据-阅卷
            getSubject({})
                .then(res => {
                    // console.log(res)
                    if (res.data.code != 200) return resolve(res.data);
                    let scoreList = res.data.data.originalSubjects; // 初评
                    let repeatedScoreList = res.data.data.originalSubjects; // 复评
                    let pathList = [];
                    let repeatedPathList = [];
                    if (scoreList.length > 0) {
                        pathList = scoreList.map((item, index) => {
                            let basePath = {
                                path: `${index + 1}`,
                                name: `/review/${index + 1}`,
                                meta: {
                                    title: item.subjectName,
                                    uuid: item.subjectUuid
                                },
                                hidden: false,
                                showNum: true,
                                component: () => import('../views/review/Color'),
                            }
                            return basePath;
                        })
                    }
                    if (repeatedScoreList.length > 0) {
                        repeatedPathList = repeatedScoreList.map((item, index) => {
                            let basePath = {
                                path: `${index + 1}`,
                                name: `/repeated/${index + 1}`,
                                meta: {
                                    title: item.subjectName,
                                    uuid: item.subjectUuid
                                },
                                hidden: false,
                                showNum: true,
                                component: () => import('../views/review/repeated'),
                            }
                            return basePath;
                        })
                    }
                    let routeList = basePath.filter(item => {
                        if (!item.hidden) {
                            if (item.name == 'review') {
                                item.children = pathList
                            } else if (item.name == 'repeated') {
                                item.children = repeatedPathList
                            }
                            return item
                        }
                    })
                    commit('updateMenuList', routeList);
                    commit('updateOriginalSubjects', res.data.data.originalSubjects);
                    commit('updateRepeatedSubjects', res.data.data.originalSubjects);
                    router.addRoutes(routeList) // 动态添加可访问路由表
                    resolve(res.data)
                })
                .catch(err => {
                    resolve(err)
                })
        })
    },
}
